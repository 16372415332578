import React from 'react';
import Section from '@latitude/section';
import Button from '@/components/lab-components/Button';
import { BUTTON_THEME, TEXT_TYPE } from '@/utils/constants';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import './_feature_col.scss';

const FeatureCol = ({
  featureColCards,
  backgroundColor,
  headingSize = 'Heading 4'
}) => {
  const colSpan = 12 / featureColCards.length;
  const Typography = TEXT_TYPE[headingSize];

  const OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography>{children}</Typography>
      )
    }
  };

  const getButtonTheme = (feature) => {
    if(feature?.buttonTheme === "Green") 
      return BUTTON_THEME.APPLICATION
    else if(feature?.buttonTheme === "Outline")
      return BUTTON_THEME.OUTLINE
    else 
      return BUTTON_THEME.DEFAULT
  }

  return (
    <div className="feature-col">
      <Section
        className={`feature-section common_tag_style feature-bg-${backgroundColor?.toLowerCase()}`}
      >
        <div className="row">
          {featureColCards?.map(feature => (
            <div
              key={feature?.id}
              className={`feature-span-${colSpan} feature-card`}
            >
              <div className={`feature-img`}>
                <img
                  src={feature?.image?.file?.url}
                  alt={feature?.image?.file?.fileName}
                />
              </div>
              <div className="feature-content">
                {feature?.headingCopy &&
                  documentToReactComponents(feature?.headingCopy, OPTIONS)}
                {feature?.description &&
                  documentToReactComponents(feature?.description)}
                {feature?.button?.url && (
                  <div className="feature-cta-wrapper">
                    <Button
                      theme={getButtonTheme(feature)}
                      href={feature?.button?.url}
                      target="_self"
                      trackId="feature-cta"
                      trackEventData={{
                        category: 'feature',
                        action: 'cta-click',
                        location: 'feature-col'
                      }}
                      text={feature?.button?.text}
                      fullWidth
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default FeatureCol;
